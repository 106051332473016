export function assessmentScoreToColor(score: Nullable<number>): string {
  if (score === null || score === undefined) {
    return 'text-white/90';
  }
  if (score < 0.7) {
    return 'text-[#FF0935]';
  }
  if (score < 0.8) {
    return 'text-[#FBB707]';
  }
  return 'text-[#39D966]';
}
